<template>
  <v-footer app absolute class="primary">
    <v-card flat tile width="100%" class="text-center primary">
      <v-row>
        <v-col cols="12" class="white--text" id="catering-link">
          For catering contact
          <a href="mailto:ineedchicken@keepitpopup.com">
            ineedchicken@keepitpopup.com
          </a>
          <br/>
          or
          <a href="mailto:thepopup.chickenshop@gmail.com">
            thepopup.chickenshop@gmail.com
          </a>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="auto" class="mx-5">
          <v-btn
            fab
            depressed
            class="primary secondary--text"
            to="/#"
            @click.prevent="$vuetify.goTo(0, options)"
            aria-label="Home"
          >
            <v-icon>fas fa-home</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="mx-5">
          <v-btn
            fab
            depressed
            class="primary secondary--text"
            to="/#location"
            @click.prevent="$vuetify.goTo('#location', options)"
            aria-label="Location"
          >
            <v-icon>fas fa-map-marker-alt</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="mx-5">
          <v-btn
            fab
            depressed
            class="primary secondary--text"
            href="https://www.facebook.com/popupchickenshop"
            aria-label="Facebook"
          >
            <v-icon>fab fa-facebook</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="mx-5">
          <v-btn
            fab
            depressed
            class="primary secondary--text"
            href="https://www.yelp.com/biz/pop-up-chicken-shop-bloomington"
            aria-label="Yelp"
          >
            <v-icon>fab fa-yelp</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="auto" class="mx-5">
          <v-btn
            fab
            depressed
            class="primary secondary--text"
            href="https://instagram.com/popupchickenshop"
            aria-label="Instagram"
          >
            <v-icon>fab fa-instagram</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="grey--text py-1 byline" justify="center">
        <v-col cols="auto"> &copy;2020-2022 Pop-Up Inc. </v-col>
        <v-col cols="auto">
        Design, DevOps, Hosting and Maintenance by
          <a 
         href="//www.linkedin.com/in/tristan-mcgowan-bestdev/"
         target="_top"
         class="grey--text"
            >Tristan McGowan</a
          >
        </v-col>  
      </v-row>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "footer-nav",

  data() {
    return {
      options: {
        offset: 54,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.byline > .col {
  font-family: "Kanit", sans-serif !important;
  font-size: 0.75em;
  letter-spacing: 0.075rem;
  a {
    // text-decoration: none;
    font-family: "Kanit", sans-serif !important;
  }
}

#catering-link {
  font-family: "Kanit", sans-serif !important;
}
#catering-link > a {
  font-family: "Kanit", sans-serif !important;
  text-decoration: none;
  color: #ffe001 !important;
}
#catering-link > a:hover,
#catering-linka:active {
  text-decoration: underline !important;
}
</style>
