<template>
  <div>
    <app-hero />
    <section class="white">
      <v-row class="text-center" no-gutters>
        <v-col>
          <v-card flat tile>
            <v-card-title class="text-center justify-center py-4 accent">
              <v-avatar color="secondary" size="48">
                <v-icon color="primary">fas fa-thumbs-up</v-icon>
              </v-avatar>
              <h1 class="popup-heading px-5">Reviews</h1>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <review-carousel></review-carousel>
    </section>

    <section id="location" class="white">
      <v-container fluid class="pa-0">
        <v-row class="text-center" no-gutters>
          <v-col>
            <v-card flat tile>
              <v-card-title class="text-center justify-center py-4 accent">
                <v-avatar color="secondary" size="48">
                  <v-icon color="primary">fas fa-map</v-icon>
                </v-avatar>
                <h1 class="popup-heading px-5">Location</h1>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row justify="center" align="start" no-gutters>
            <iframe
                width="100%"
                height="450"
                frameborder="0"
                style="border: 0"
                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJUciXagpwC4gR160zroyl0KI&key=AIzaSyAV4gnRBGWXPKNhr_7r0eLaNao2CsEj07I&zoom=17"
                allowfullscreen
                title="Google Maps"
            >
            </iframe>
        </v-row>
      </v-container>
    </section>

    <v-row align="center" no-gutters id="menu">
      <v-col cols="12">
        <v-card flat tile>
          <v-card-title class="text-center justify-center py-4 accent">
            <v-avatar color="secondary" size="48">
              <v-icon color="primary">fas fa-list-alt</v-icon>
            </v-avatar>
            <h1 class="popup-heading px-5">Menu</h1>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>


    <v-row justify="center" class="accent lighten-5">
      <v-col cols="auto">
        <v-card>
          <popup-menu></popup-menu>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import AppHero from "@/components/AppHero.vue";
import ReviewCarousel from "@/components/ReviewCarousel.vue";
import Menu from "@/views/Menu.vue";

export default {
  name: "home",
  components: {
    "app-hero": AppHero,
    "review-carousel": ReviewCarousel,
    "popup-menu": Menu,
  },
};
</script>

<style lang="scss">
i.fi {
  font-size: 1.5em;
}
</style>

<style>
/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .v-btn.v-size--small {
        font-size: .75rem !important;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }

}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
      .v-btn.v-size--small {
        font-size: .75rem !important;
    }

}
</style>
